<script setup lang="ts">
function standardOrLighterColor() {
  return useOrganizerStore().organizer.colorConfig.shades.standardOrLighterIfDark
}
</script>

<template>
  <v-btn v-bind="$attrs" :color="standardOrLighterColor()" large>
    <span class="black--text">
      <slot></slot>
    </span>
  </v-btn>
</template>